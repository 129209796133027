import { useEffect, useRef, useState } from "react";
import "./App.scss";

function App() {
    const [counter, setCounter] = useState(0);
    const [isAudioPlaying, setIsAudioPlaying] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);
    const audioRef = useRef<HTMLAudioElement>(null);
    const sceneDuration = 466.875; // Scene duration in milliseconds

    const name = window.location.host.split(".")[0];

    useEffect(() => {
        document.title = name + " is gay";
    }, []);

    useEffect(() => {
        const videoElement = videoRef.current;
        const audioElement = audioRef.current;

        if (!videoElement || !audioElement) return;

        let lastTime = 0;
        let accumulatedTime = 0;

        const handleTimeUpdate = () => {
            const currentTime = videoElement.currentTime * 1000; // Convert to milliseconds
            const deltaTime = currentTime - lastTime;
            lastTime = currentTime;
            accumulatedTime += deltaTime;

            if (accumulatedTime >= sceneDuration) {
                // A scene has passed
                const scenesPassed = Math.floor(
                    accumulatedTime / sceneDuration
                );
                setCounter((prevCounter) => prevCounter + scenesPassed);
                accumulatedTime %= sceneDuration;
            }

            if (currentTime >= videoElement.duration * 1000 - 300) {
                // Just before the video ends
                videoElement.currentTime = 0;
                if (isAudioPlaying) {
                    audioElement.currentTime = 0;
                    audioElement.play();
                }
                videoElement.play();
                lastTime = 0;
                accumulatedTime = 0;
            }
        };

        videoElement.addEventListener("timeupdate", handleTimeUpdate);

        const handleAutoplay = async () => {
            try {
                await videoElement.play();
            } catch (error) {
                console.error("Video autoplay failed:", error);
            }
        };

        handleAutoplay();

        return () => {
            videoElement.removeEventListener("timeupdate", handleTimeUpdate);
        };
    }, [sceneDuration, isAudioPlaying]);

    const handleAudioPlay = async () => {
        const audioElement = audioRef.current;
        const videoElement = videoRef.current;
        if (audioElement && videoElement) {
            try {
                // Sync audio with the current video time
                audioElement.currentTime = videoElement.currentTime;
                await audioElement.play();
                setIsAudioPlaying(true);
            } catch (error) {
                console.error("Audio play failed:", error);
            }
        }
    };

    return (
        <div className="container">
            <div>
                <h1>{name} is gay</h1>
                <br />
                {counter > 50 && (
                    <>
                        <h2>You watched over 50 loops</h2>
                        <h2>You are officially gay!</h2>
                    </>
                )}
                <video ref={videoRef} width="600" muted>
                    <source src="/Movie.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <audio ref={audioRef}>
                    <source src="/Audio.mp3" type="audio/mp3" />
                    Your browser does not support the audio tag.
                </audio>
                <br />
                <button onClick={handleAudioPlay} disabled={isAudioPlaying}>
                    {isAudioPlaying ? "Audio Playing" : "Play Audio"}
                </button>
                <p className="loop-count">Loop count: {counter}</p>
            </div>
        </div>
    );
}

export default App;
